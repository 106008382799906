<template>
  <Login />
</template>
<style scoped></style>
<script>
import Login from "@/components/Login";
export default {
  name: "LoginView",
  components: {
    Login
  }
};
</script>
